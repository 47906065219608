<template>
  <div class="crafting logs view">
    <app-header :title="'Crafting Logs'"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <div class="control-bar__search mr ml">
        <select-reference
            v-model="userId"
            :endpoint="'/community/user'"
            :field-icon="'avatar'"
            :field-title="'userName'"
            :nothing-selected-message="__('filterByUser')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="itemId"
            :endpoint="'/ingame/items/item'"
            :field-icon="'icon'"
            :field-title="'full-name'"
            :nothing-selected-message="__('filterByItem')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh()" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="craftingLogs"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @sort="sort"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      AppHeader,
      DataTable,
    },
    data: () => ({
      loading: false,
      craftingLogs: [],
      userId: null,
      itemId: null,
      dataTableFields: [],
      recordActions: [],
    }),
    watch: {
      userId() {
        this.pagination.page = 1;
      },
      itemId() {
        this.pagination.page = 1;
      },
    },
    methods: {
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        if (this.userId !== null) {
          params.userIds = this.userId;
        }
        if (this.itemId !== null) {
          params.itemIds = this.itemId;
        }
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/items/craftinglog', { params }).then(async (res) => {
          this.craftingLogs = res.data;
          this.updatePagination(res);
          await this.enrichRecords();
          this.loading = false;
          this.showInfoNotification('Logs loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecords() {
        const userIds = [];
        const itemIds = [];
        this.craftingLogs.forEach(({ userId, itemId }) => {
          if (!userIds.includes(userId)) {
            userIds.push(userId);
          }
          if (!itemIds.includes(itemId)) {
            itemIds.push(itemId);
          }
        });
        let users = [];
        let items = [];
        if (userIds.length > 0) {
          await apiSecured.get(`/community/user/(${userIds.join(',')})`, {
            params: {
              fields: 'id,userName,avatar'
            },
          }).then((res) => {
            users = res.data;
            this.showInfoNotification('Users have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
        if (itemIds.length > 0) {
          await apiSecured.get(`/ingame/items/item/(${itemIds.join(',')})`, {
            params: {
              fields: 'id,fullName,icon'
            },
          }).then((res) => {
            items = res.data;
            this.showInfoNotification('items have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }

        this.craftingLogs = this.craftingLogs.map(craftingLog => {
          const user = users.find(user => user.id === craftingLog.userId);
          const item = items.find(item => item.id === craftingLog.itemId);
          craftingLog.userid = user.id;
          craftingLog.userAvatar = user.avatar ? user.avatar : 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
          craftingLog.userName = user.userName;
          craftingLog.itemId = item.id;
          craftingLog.itemIcon = item.icon;
          craftingLog.itemName = item.fullName;
          return craftingLog;
        });
      },
    },
    created() {
      this.setPageTitle('Crafting Logs');
      if ('userId' in this.$route.query) {
        const userId = this.$route.query.userId;
        if (userId !== null && userId !== undefined) {
          this.userId = userId;
        }
      }
      if ('itemId' in this.$route.query) {
        const itemId = this.$route.query.itemId;
        if (itemId !== null && itemId !== undefined) {
          this.itemId = itemId;
        }
      }
      this.dataTableFields = [
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "userAvatar", title: 'Avatar', type: "image", disableSorting: true },
        { name: "userName", title: 'User', type: "default", disableSorting: true },
        { name: "itemIcon", title: 'Item Icon', type: "image", disableSorting: true },
        { name: "itemName", title: 'Item Name', type: "default", disableSorting: true },
        { name: "amount", title: this.__('component.crafting_calculator.amount'), type: "numeric" },
        { name: "ignoreIngredientConfigurations", title: this.__('ignoreIngredientConfigurations'), type: "boolean-text" },
        { name: "onlyDirect", title: this.__('onlyDirect'), type: "boolean-text" },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [];
      this.refresh();
    }
  }
</script>
